import React from 'react';
import _ from 'lodash';
import AppProvider from 'store/provider';
import wrapPageElementWithTransition from 'helpers/wrapPageElement';
import breakpoints from 'constants/breakpoints';

// React Context in Browser
// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => {
  return <AppProvider>{element}</AppProvider>;
};

// Page Transitions
export const wrapPageElement = wrapPageElementWithTransition;

const debouncedResize = _.debounce(makeImagesNotOverlap, 100);

export const onRouteUpdate = () => setTimeout(debouncedResize, 200);
export const onInitialClientRender = () => {
  window.addEventListener('resize', debouncedResize);
  debouncedResize();
}

function makeImagesNotOverlap() {
  const sidebarAnchors = document.querySelectorAll('.sidebar-anchor');
  const isPageDesktopSize = document.body.clientWidth > breakpoints.TABLET;

  if (isPageDesktopSize && sidebarAnchors.length > 1) {
    setTopMarginsOnAnchors(sidebarAnchors);
  } else {
    resetTopMarginsOnAnchors(sidebarAnchors);
  }
}

function setTopMarginsOnAnchors(sidebarAnchors) {
  sidebarAnchors.forEach((anchor, i, anchors) => {
    // SHOW THE FIRST IMAGE WHEREVER IT IS
    if (i === 0) return;

    const thisAnchors = anchors[i].querySelectorAll('span.gatsby-resp-image-wrapper');
    const previousAnchors = anchors[i-1].querySelectorAll('span.gatsby-resp-image-wrapper');

    if (thisAnchors.length === 0) return;

    // BRING THE IMAGE BACK TO NORMAL LOCATION BEFORE DOING MATH
    thisAnchors.forEach((anchor) => {
      anchor.style.marginTop = 0;
    });

    const thisAnchor = thisAnchors[0];
    const previousAnchor = previousAnchors[previousAnchors.length - 1];

    // give thisAnchor marginTop equal to difference of thisAnchor's offsetTop and previousAnchor's offsetBottom
    const thisAnchorDistanceFromTop = window.pageYOffset + thisAnchor.getBoundingClientRect().top;
    const previousAnchorDistanceFromTop = window.pageYOffset + previousAnchor.getBoundingClientRect().bottom;
    const overlapBetweenElements = thisAnchorDistanceFromTop - previousAnchorDistanceFromTop;

    // IF THE IMAGES DON'T OVERLAP DON'T ADJUST THEM
    if (overlapBetweenElements < convertRemToPixels(2)) {
      const newMarginTop = Math.abs(overlapBetweenElements) + convertRemToPixels(2);
      thisAnchors.forEach((anchor) => {
        anchor.style.marginTop = `${newMarginTop}px`;
      });
    } else {
      thisAnchors.forEach((anchor) => {
        anchor.style.marginTop = 0;
      });
    }
  });
}

function resetTopMarginsOnAnchors(sidebarAnchors) {
  if (!sidebarAnchors.length || !sidebarAnchors[0].querySelector('span')) return;

  sidebarAnchors.forEach((sidebarAnchor) => {
    sidebarAnchor.querySelectorAll('span').forEach((anchor) => {
      anchor.style.marginTop = 0;
    })
  })
}

function convertRemToPixels(rem) {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}